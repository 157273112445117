@import '../functions';
$primary: #4468ae;
@import '../variables';
@import '../mixins';

// HOME B2B

.hero-home{
	background: url('../../img/background/bg-home-b2b.jpg');
	background-size: cover;
	background-position: center;
	position: relative;
}

.home{

	&.universes{
		background-color: $white;
	}

	&.stories,
	&.newsletter{
		.title{
			h2{
				color: $primary;
			}
		}
	}

	&.stories{
		background-color:  $white;

		@include media-breakpoint-up(sm) {
			background-image: url('../../img/background/bg-office.jpg');
			background-position: bottom center;
			background-size: cover;
		}

		.stories-wrapper{
			@include media-breakpoint-only(sm) {
				padding-bottom: 20vw;
			}
			@include media-breakpoint-only(md) {
				padding-bottom: 10vw;
			}
			@include media-breakpoint-only(lg) {
				padding-bottom: 12vw;
			}
			@include media-breakpoint-up(xl) {
				padding-bottom: 15vw;
			}
		}

		.story-link{
			&.see_more{
				a{
					background-color: $primary;
					color: $white;
					&:hover{background-color: darken($primary, 10%);}
				}
			}
		}
	}

	&.how_to{
		background-color: $white;

		.how_to-wrapper{
			@include media-breakpoint-up(lg) {
				margin-top: -100px;
			}
		}

		.how_to-overview{
			background-color: $primary;
			color: $white;

			.title{
				margin-bottom: $spacer * 2;

				h1{
					font-weight: 300;

					strong{
						font-weight: 700;
					}
				}
			}

			.btn-link{
				color: $secondary;
				padding: 0;
				font-weight: 700;
				letter-spacing: .2em;
				
				&.active,
				&:hover,
				&:focus,
				&:active{
					color: $white;
					text-decoration: none;
				}

			}
		}
	}

	&.newsletter{
	}

	// b2b blocks

	&.services{
		background-color: $white;
		padding: 7rem 0;

		.left{
			display: flex;
			flex-direction: column;
			justify-content: center;

			h1{
				font-size: rem(22)
			}

			h2{
				font-size: rem(24);
				font-weight: 400;
			}

			@include media-breakpoint-down(md) {
				margin-bottom: $spacer * 2;
			}
		}

		.right{
			border: 1px solid $lighter;
			border-bottom: transparent;
		}

		.services-wrapper{
			padding: $spacer;
		}

		.service{
			text-align: center;
			position: relative;

			&:first-child{
				@include media-breakpoint-down(md) {
					border-bottom: 1px solid $lighter;
					padding: $spacer $spacer $spacer * 2;
				}

				@include media-breakpoint-up(lg) {
					border-right: 1px solid $lighter;
				}
			}

			&:last-child{
				@include media-breakpoint-down(md) {
					padding: $spacer * 2 $spacer $spacer;
				}
			}

			.title{
				font-weight: 700;
				font-size: rem(20);

				.script{
					display: block;
					color: $kaki;
					font-weight: 400;
				}
			}

			hr{
				background-color: $kaki;
				width: 50px;
			}

			.price-model{
				text-transform: uppercase;
				font-weight: 700;
				margin-bottom: $spacer / 2;
			}

			p{
				font-size: rem(12);
				color: $secondary;
				font-family: $font-family-alt;
				margin: $spacer 0 $spacer * 2;
			}

			.action{

				@include media-breakpoint-up(lg) {
					position: absolute;
					bottom: calc(-#{$spacer} - 17px);
					left: 50%;
					margin-left: -17px;
				}
			}
		}

		.services-details{
			position: relative;
			top: -1px;

			.left{
				border: 1px solid $lighter;
				padding: $spacer / 2 $spacer;
				background-color: $white;
				border-right: transparent;
			}

			.left{

			}

			.right{
				border-left: transparent;
				border-top: transparent;
				border-bottom: 1px solid $lighter;
			}

			.services-details-trigger{
				text-transform: uppercase;
				font-size: rem(10);
				color: $secondary;
				font-family: $font-family-alt;
			}
		}
	}

}